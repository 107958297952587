import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import { Layout } from "../home/Layout";
import AboutUs from "./About";

export const StyledContainer = styled.div<{ f?: any }>`
	@media (min-width: 1200px) {
		/* Styles for devices with a minimum width of 1200px */
		min-height: ${(props) => (!!props.f ? "calc(100vh - 100px)" : "100vh")};
	}
`;
export const StyledAdvisorImage = styled.img`
	width: 160px;
	height: 160px;
`;

const advisors = [
	{ name: "Arul Murugan A", src: "Asserts/advisors/arul.png" },
	{ name: "Dhanachandran E", src: "Asserts/advisors/Dhanachandran E.jpeg" },
	{
		name: "Geetha Karthik Netha",
		src: "Asserts/advisors/Geetha Karthik Netha.JPG",
	},
	{ name: "Gopi Vedachalam", src: "Asserts/advisors/Gopi Vedhachalam.jpg" },
	{ name: "Jamunarani", src: "Asserts/advisors/Jamuna Rani.jpg" },
	{ name: "Karthik Netha", src: "Asserts/advisors/Karthik Netha.jpg" },
	{ name: "Mohan E", src: "Asserts/advisors/Mohan E.jpg" },
	{
		name: "Rajendran Durairaj",
		src: "Asserts/advisors/Rajendran Durairaj.png",
	},
	{
		name: "Santhanakrishnan P",
		src: "Asserts/advisors/Santhanakrishnan P.jpeg",
	},
	{ name: "Sathish Nagarajen", src: "Asserts/advisors/Sathish Nagarajen.jpeg" },
	{ name: "Shiv Verma", src: "Asserts/advisors/Shiv Verma.JPG" },
	{ name: "Steven Boscovitch", src: "Asserts/advisors/Steven Boscovitch.jpg" },
];

const languageAmbassadors = [
	{
		name: "Ahil Ibrahim",
		src: "Asserts/language ambassadors/Ahil Ibrahim_Malayalam.jpg",
		language: "Malayalam",
	},
	{
		name: "Andrew Driscoll",
		src: "Asserts/language ambassadors/Andrew Driscoll_Spanish.jpg",
		language: "Spanish",
	},
	{
		name: "Akilan Prasanna",
		src: "Asserts/language ambassadors/Akilan P_Outreach.png",
		language: "Outreach",
	},
	{
		name: "Boshudeb Chandra",
		src: "Asserts/language ambassadors/Boshudeb Chandra_Kannada.jpg",
		language: "Kannada & Telugu",
	},
	{
		name: "Chetan Vanga",
		src: "Asserts/language ambassadors/Chetan Vanga_Telugu & Business Outreach.jpg",
		language: "Telugu & Business Outreach",
	},
	{
		name: "Dr Abanob",
		src: "Asserts/language ambassadors/Dr Abanob_Arabic.jpg",
		language: "Arabic",
	},
	{
		name: "Maggie Driemeyer",
		src: "Asserts/language ambassadors/Maggie Driemeyer_Spanish.jpg",
		language: "Spanish",
	},
	{
		name: "Mubashir Kazi",
		src: "Asserts/language ambassadors/Mubashir Kazi_Bengali.jpg",
		language: "Bengali",
	},
	{
		name: "Naveen Kumar R",
		src: "Asserts/language ambassadors/Naveen Kumar R_Tamil & Technology.jpeg",
		language: "Tamil & Technology",
	},
	{
		name: "Olivia Conrad",
		src: "Asserts/language ambassadors/Olivia Conrad_German.png",
		language: "German",
	},
	{
		name: "Piyush Zawar",
		src: "Asserts/language ambassadors/Piyush Zawar_Marathi & Hindi.jpg",
		language: "Marathi & Hindi",
	},
	{
		name: "Prathima Prakash",
		src: "Asserts/language ambassadors/Prathima Prakash_Telugu & Business Outreach.jpg",
		language: "Telugu & Business Outreach",
	},
	{
		name: "Raga Gourineni",
		src: "Asserts/language ambassadors/Raga Gourineni_Telugu & Business outreach.jpg",
		language: "Telugu & Business Outreach",
	},
	{
		name: "Razvan Nicolae",
		src: "Asserts/language ambassadors/Razvan Nicolae_Romanian & Technology.jpg",
		language: "Romanian & Technology",
	},
	{
		name: "Rohan Rajendran",
		src: "Asserts/language ambassadors/Rohan Rajendran_Business Ourtreach.jpg",
		language: "Business Outreach",
	},
	{
		name: "Sana Waqar",
		src: "Asserts/language ambassadors/Sana Waqar_Urdu.jpg",
		language: "Urdu",
	},
	{
		name: "Yuvaraj P",
		src: "Asserts/language ambassadors/Yuvaraj P_Tamil & Technology.jpeg",
		language: "Tamil & Technology",
	},
	{
		name: "Zachary Zhu",
		src: "Asserts/language ambassadors/Zachary Zhu_Technology & Outreach.jpg",
		language: "Technology & Outreach",
	},
	{
		name: "Joanna P",
		src: "Asserts/language ambassadors/Joanna P_Telugu & Outreach.png",
		language: "Telugu & Outreach",
	},
	{
		name: "Sophia W",
		src: "Asserts/language ambassadors/Sophia W_Outreach.png",
		language: "Outreach",
	},
	{
		name: "Chloe O",
		src: "Asserts/language ambassadors/Chloe O_French.png",
		language: "French",
	},
	{
		name: "Skylar Fan",
		src: "Asserts/language ambassadors/Skylar Fan_Turkish.png",
		language: "Turkish",
	},
	{
		name: "Srishashvath Challa",
		src: "Asserts/language ambassadors/SrishashvathChalla.jpeg",
		language: "Telugu & Outreach",
	},
];

advisors.sort((a, b) => a.name.localeCompare(b.name));
languageAmbassadors.sort((a, b) => a.name.localeCompare(b.name));

const AboutUsHome = () => {
	return (
		<Layout>
			<div>
				<AboutUs />
				<Container fluid className="bg-light">
					<StyledContainer className="">
						<div>
							<Row>
								<Col>
									<p className="cmn-font-h2 text-center">advisors</p>
								</Col>
							</Row>
							<br />
							<br />

							<Row className="gy-5">
								{advisors.map((advisor, index) => (
									<Col
										key={index}
										lg={3}
										md={4}
										sm={2}
										className="text-center "
									>
										<StyledAdvisorImage
											className="img-fluid rounded-circle object-fit-fill"
											src={advisor.src}
											alt=""
										/>
										<div className="mt-3 fs-5 fw-bold text-primary">
											{advisor.name}
										</div>
										<br className="d-none d-lg-block" />
										<br className="d-none d-lg-block" />
										<br className="d-none d-lg-block" />
									</Col>
								))}
							</Row>
						</div>
					</StyledContainer>
				</Container>
				<Container style={{ marginTop: 32 }} fluid className="">
					<StyledContainer>
						<div>
							<Row>
								<Col>
									<p className="cmn-font-h2 text-center">
										language ambassadors
									</p>
								</Col>
							</Row>
							<br />
							<br />

							<Row className="gy-5">
								{languageAmbassadors.map((ambassador, index) => (
									<Col
										key={index}
										lg={3}
										md={4}
										sm={2}
										className="text-center "
									>
										<StyledAdvisorImage
											className="img-fluid rounded-circle object-fit-fill"
											src={ambassador.src}
											alt=""
										/>
										<div className="mt-3 fs-5 fw-bold text-primary">
											{ambassador.name} <br />
											<span style={{ color: "#b3b3b3" }}>
												{ambassador.language}
											</span>
										</div>
										<br className="d-none d-lg-block" />
										<br className="d-none d-lg-block" />
										<br className="d-none d-lg-block" />
									</Col>
								))}
							</Row>
						</div>
					</StyledContainer>
					<br />
					<br />
				</Container>
				{/* <Team /> */}
			</div>
		</Layout>
	);
};

export default AboutUsHome;
