import { useEffect, useMemo, useState } from "react";
import { useAppContextWrapper } from "../../shared/contexts/AppContextWrapper";
import { useUserContextWrapper } from "../../shared/contexts/UserContextWrapper";
import { useAppHook } from "../../shared/hooks/useAppHook";
import { Layout } from "../home/Layout";
import { Cards } from "./Card";
import { FlashCaredStyle } from "./flashcared";
import { MyCategoriesDropdown } from "./MyCategoriesDropdown";

export interface IState {
	profId: string;
	customCategoryId: string;
}

export const FlashcardsHome = () => {
	const {
		getAllLanguages,
		getAllSharedCategoriesByUserId,
		getAllProfLevels,
		getCategoriesByProfLevel,
		getAllMyCategoriesByUserId,
	} = useAppHook();
	const {
		profLevels,
		sharedCategoriesById,
		myCategories,
		categoriesByProf,
		flashCardProfLevel,
		flashCardsMyCustomCategory,
		flashCardsSourceLang,
		flashCardsTargetLang,
		flashCardBowlCategory,
	} = useAppContextWrapper();
	const { user } = useUserContextWrapper();
	const userId = useMemo(() => user?.userId ?? "", [user]);
	const { isLoading } = useAppContextWrapper();

	useEffect(() => {
		if (userId)
			Promise.all([getAllMyCategoriesByUserId({ userId: userId })]).then();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userId]);
	useEffect(() => {
		Promise.all([
			getAllProfLevels(),
			getAllSharedCategoriesByUserId({ userId: userId }),
			// getAllUsers(),
			getAllLanguages(),
		]).then();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const profLevel = useMemo(() => profLevels[0]?.profLevel, [profLevels]);
	const myCategoryIdValue = useMemo(() => {
		if (flashCardProfLevel === "myCategory")
			return myCategories?.result[0]?.customCategoryId;
		else if (flashCardProfLevel === "sharedCategory")
			return sharedCategoriesById?.result[0]?.customCategoryId;
		else return "";
	}, [myCategories, sharedCategoriesById, flashCardProfLevel]);

	const [state, setState] = useState<IState>({
		profId: "",
		customCategoryId: "",
	});

	useEffect(() => {
		setState((prev) => ({
			...prev,
			profId: flashCardProfLevel ? flashCardProfLevel : profLevel,
			customCategoryId: flashCardsMyCustomCategory
				? flashCardsMyCustomCategory
				: myCategoryIdValue,
		}));

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		flashCardProfLevel,
		profLevel,
		flashCardsMyCustomCategory,
		myCategoryIdValue,
	]);

	const isMyCategory = () => {
		if (state.profId === "myCategory" || state.profId === "sharedCategory") {
			return state.customCategoryId !== "";
		} else return true;
	};

	useEffect(() => {
		getCategoriesByProfLevel({
			profLevel: flashCardProfLevel || profLevels[0]?.profLevel,
			sourceLanguage: flashCardsSourceLang,
			targetLanguage: flashCardsTargetLang,
			customCategoryId: flashCardsMyCustomCategory || myCategoryIdValue,
			bowlCategoryId: flashCardBowlCategory,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		state,
		profLevels,
		flashCardsSourceLang,
		flashCardsTargetLang,
		myCategoryIdValue,
		flashCardProfLevel,
		flashCardBowlCategory,
	]);

	return (
		<div>
			<Layout>
				<MyCategoriesDropdown setState={setState} state={state} />
				<div
					className=" col-9 pb-4 container-fluid scrollbar scrollbar-primary   d-flex flex-wrap align-items-center justify-content-center"
					style={{
						height: "calc(100vh - 290px)",
					}}
				>
					{isLoading ? (
						<FlashCaredStyle className="spinner-border" role="status">
							<span className="visually-hidden">Loading...</span>
						</FlashCaredStyle>
					) : (
						<>
							{isMyCategory() &&
								categoriesByProf?.result?.map((d) => {
									return (
										<FlashCaredStyle
											key={d.categoryId}
											className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-12 "
										>
											<div className="card-wrapper">
												<Cards
													sourceLanguage={flashCardsSourceLang}
													targetLanguage={flashCardsTargetLang}
													categoryId={d.categoryId}
													categoryName={d}
													customCategoryId={state.customCategoryId}
													sourceVocabId={d.language1VocId || d.vocabularyId}
													targetVocabId={d.language2VocId}
												/>
											</div>
										</FlashCaredStyle>
									);
								})}
						</>
					)}
				</div>
			</Layout>
		</div>
	);
};
